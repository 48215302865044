<template>
   <div class="autograph">
        <van-nav-bar
            title="签名"
            safe-area-inset-top 
            fixed
            placeholder
            left-arrow
            @click-left="onClickLeft"
            :border="false"
       />
        <div class="sign-btns">
            <span id="clear" @click="canvasClear()">重写</span>
            <span id="save" @click="close()">取消</span>
            <span id="load" @click="confirm()">确认</span>
        </div>
        <div class="tip">
          <sign-canvas class="sign-canvas" ref="SignCanvas" :options="options" v-model="value"/>
          <div class="tips"></div>
        </div>
     

    <div class="sing-text"><div>手写板</div></div>
   </div>
</template>

<script>
import *as api from '@/utils/api'
import axios from'axios'
import lrz from 'lrz'

export default {
    data(){
        return {
            value: true,
            options:{
                lastWriteSpeed: 1,  //书写速度 [Number] 可选
                lastWriteWidth: 2,  //下笔的宽度 [Number] 可选
                lineCap: 'round',   //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
                lineJoin: 'round',  //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
                canvasWidth: 243, //canvas宽高 [Number] 可选
                canvasHeight: document.body.clientHeight - 90,  //高度  [Number] 可选
                isShowBorder: false, //是否显示边框 [可选]
                bgColor: '#fff', //背景色 [String] 可选 注:这背景色仅仅只是canvas背景,保存的图片仍然是透明的
                borderWidth: 1, // 网格线宽度  [Number] 可选
                borderColor: "#ff787f", //网格颜色  [String] 可选
                writeWidth: 3, //基础轨迹宽度  [Number] 可选
                maxWriteWidth: 3, // 写字模式最大线宽  [Number] 可选
                minWriteWidth: 3, // 写字模式最小线宽  [Number] 可选
                writeColor: '#101010', // 轨迹颜色  [String] 可选
                isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
                imgType:'png',   //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
            },
            baidu_access_token:'',
            baiduBaseUrl:process.env.NODE_ENV == "dev" || process.env.NODE_ENV == "test" || process.env.NODE_ENV == "show" ? "/baidubce" : "https://api.shenzhenyht.com/baidubce",
            fileBaseUrl:process.env.NODE_ENV == "dev"? "/shenzhenyht": "https://shenzhenyht.oss-cn-shenzhen.aliyuncs.com",
            localtoken:localStorage.getItem("token"),
            sellAutographKey:'',
            path:'',
            userinfo:{},
            number:0
        }
    },
    mounted() {
      this.userinfo = JSON.parse(localStorage.getItem('userinfo')) || {}
      this.path = this.$route.query.pricing || false
      this.getAccess_token()
    },
    methods:{
      onClickLeft(){
          this.$router.push({
            name:"AccountContract"
          })
      },
        /**
         * 清除画板
         */
      canvasClear(){
            this.$refs.SignCanvas.canvasClear()
      },
      close(){
          this.$router.push({
            name:"AccountContract"
          })
          // this.$router.push({
          //   name:this.path
          // })
      },
      confirm(){
              /**
                * 保存图片
              */
          const img = this.$refs.SignCanvas.saveAsImg();
              const data = {
                       size: 1
                };
          api.apiFile(data).then((res) => {
  
                this.sellAutographKey = res[0].key
                this.uploadFile(img, res[0].url);
                   lrz(img, {
                      quality: 0.2 //自定义使用压缩方式
                    }).then((rst)=>{

                        const data = {
                            image: encodeURI(rst.base64).slice(22),
                            detect_direction:'true'
                          };
                      this.baiduAi(data);
                    })
        });
      },
      // 第二步上传文件
      uploadFile(imgpath,url) {
    // debugger;
    // 去掉url域名 保留后面
       const urllast =
      "/" +
      url
        .split("/")
        .slice(3)
        .join("/");
    const configs = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + this.localtoken
      }
    };
    const transformRequest = [
      function(data) {
        // debugger;
        let ret = "";
        for (const it in data) {
          ret +=
            encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        return ret;
      }
    ];
    const imgarr = this.base64ToUint8Array(imgpath);
    console.log('imgarr',imgarr);
    axios({
      url: this.fileBaseUrl + urllast,
      method: "put",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + this.localtoken
      },
      data: imgarr
    }).then(res => {
      console.log("请求结果：", res);
     
    }).catch(err=>{
        this.$toast({
          message:'图片上传失败',
          forbidClick:true,
          getContainer:'.autograph'
        })
    });

      },
      base64ToUint8Array(base64String) {
    // debugger;
      const base64clear = base64String.substring(base64String.indexOf(",") + 1);
      const padding = "=".repeat((4 - (base64clear.length % 4)) % 4);
      const base64 = (base64clear + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      },  
      // 百度获取API
      getAccess_token() {
      const that = this;
      // 百度识别API
      axios
        .post(
          `${this.baiduBaseUrl}/oauth/2.0/token?grant_type=client_credentials&client_id=np5Z2o86jTLStdZxCnFPm6Hm&client_secret=XBGA3SMlb2koPGMG4Xjo0zjEEFtxz64A`
        )
        .then(res => {
          const { access_token } = res.data;
          this.baidu_access_token = access_token;
          console.log(this.baidu_access_token);
        });
      },
      baiduAi(data) {
      const that = this;
      const token = that.baidu_access_token;
      console.log('data',data);
      const transformRequest = [
        function(data) {
          let ret = "";
          for (const it in data) {
            ret +=
              encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
          }
          return ret;
        }
      ];

      const configheaders = {
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      };
      axios
        .post(
          `https://aip.baidubce.com/baidubce/rest/2.0/ocr/v1/handwriting?access_token=` + token,
          data,
          { transformRequest ,headers:{ "content-type": "application/x-www-form-urlencoded"} }
        )
        .then(res => {
         
          console.log('res1',res);
          if (res.data.words_result_num != 0 && res.data.words_result.length !=0) {
            console.log("userinfo:", this.userinfo)
            let resAutograph = ""
            res.data.words_result.forEach(item=>{
              resAutograph += item.words
            })
            console.log("resAutograph:", resAutograph)
            const srcAutograph = this.userinfo.realName || JSON.parse(localStorage.getItem("idcrad")).name;
            console.log("srcAutograph:", srcAutograph)
            if(srcAutograph !== resAutograph){
              throw new Error("签名校验失败,请重新签名");
            }
            else {
              this.number = 3

              const timer = setInterval(() => {

                this.$toast({
                  message: `签名成功(${this.number})`,
                  forbidClick: true,
                  getContainer: '.autograph'
                })
                this.number--
                if (this.number == 0) {
                  clearInterval(timer)
                  this.$toast.clear()
                  api.getUserSign({ signKey: this.sellAutographKey }).then((res) => {
                    if (  this.path  && this.userinfo.auth != 'UNPOST' && this.userinfo.auth != 'FAIL') {
                          this.$router.push({
                            name:'pricing'
                          })
                    }else{
                    this.$router.push({
                      name: 'successpage',
                      query: { type: 'usercerti' }
                    })
                    }
                  }).catch((err) => {
                    this.$toast({ message: '网络错误,请稍后再试!!!', forbidClick: true, getContainer: '.autograph' })
                  })
                }
              }, 1000)
            }
          } else {
            throw new Error("返回数据不规范");
          }
        })
        .catch(error => {
          console.error(error);
          that.$toast({
            message:'签名校验失败,请重新签名',
            forbidClick:true,
            getContainer:'.autograph'
          });
          this.canvasClear()
        });
    },
    }
  }
</script>

<style lang="scss" scoped>
::v-deep{
     .van-nav-bar{
        height: 90px;
        margin-bottom: 90px;
        .van-nav-bar__content{
        line-height: 90px;
        height: 90px;
        background-color: #fff;
        .van-nav-bar__title{
            font-size: 32px;
            color: #242424;
        }
        .van-icon{
            font-size: 36px;
            color: #242424;
            right: 0px;
          }
        }
     }
     .van-toast{
      transform:rotate(90deg);
      left: 40%;
     }
}
.autograph{
  height: 100%;
  background-color: #F5F5F5;
  border: 1px solid transparent;
  display: flex;
  padding: 120px 0 0;
}
.title{
    padding: 20px;
    text-align: center;
}
.sign-canvas{
    display: block;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #E9E9E9;
    //height: 96% !important;
    bottom: 30px;
}
.view-image{
    display: block;
    margin: auto;
}
.config{
    width: 350px;
    margin: 20px auto;
    .ul-config{
        .li-c{
            display: flex;
            align-items: center;
            padding: 4px 10px;
            .item-label{
                font-size: 14px;
            }
            .item-content{
                margin-left: 10px;
            }
        }
    }
}
.sing-text{
    div{
        transform:rotate(90deg);
        font-size: 32px;
        width: 100px;
        line-height: 100px;
        position: absolute;
        top: 50%;
        // text-align: center;
    }
}
.sign-btns{
    display: flex;
    flex-direction:column;
    height: 100%;
    position: relative;
    #clear,
    #load,
    #save {
        transform:rotate(90deg);
        display: inline-block;
        width: 186px;
        height: 78px;
        line-height: 78px;
        border: 1px solid #999999;
        background: #F5F5F5;
        color: #999999;
        border-radius: 50px;
        text-align: center;
        font-size: 28px;
        margin: 60px auto;
        cursor: pointer;
    }
    #save{
      margin-top: 100px;
    }
    #load{
      margin-top: 580px;
      position: absolute;
      bottom: 20px;
      background-color: #FF2D2E;
      color: #fff;
      border: none;
    }
}
.tip{
  position: relative;
}
.tips{
  // transform:rotate(90deg);
  width: 128px;
  height: 168px;
  background:rgba($color:pink, $alpha: 0.2);
  background: url('~@/assets/images/pic_autograph@2x.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 0;
}
</style>