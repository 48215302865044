var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autograph" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "签名",
          "safe-area-inset-top": "",
          fixed: "",
          placeholder: "",
          "left-arrow": "",
          border: false
        },
        on: { "click-left": _vm.onClickLeft }
      }),
      _c("div", { staticClass: "sign-btns" }, [
        _c(
          "span",
          {
            attrs: { id: "clear" },
            on: {
              click: function($event) {
                return _vm.canvasClear()
              }
            }
          },
          [_vm._v("重写")]
        ),
        _c(
          "span",
          {
            attrs: { id: "save" },
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          },
          [_vm._v("取消")]
        ),
        _c(
          "span",
          {
            attrs: { id: "load" },
            on: {
              click: function($event) {
                return _vm.confirm()
              }
            }
          },
          [_vm._v("确认")]
        )
      ]),
      _c(
        "div",
        { staticClass: "tip" },
        [
          _c("sign-canvas", {
            ref: "SignCanvas",
            staticClass: "sign-canvas",
            attrs: { options: _vm.options },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          }),
          _c("div", { staticClass: "tips" })
        ],
        1
      ),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sing-text" }, [
      _c("div", [_vm._v("手写板")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }